<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- personal Details -->
          <b-col cols="12">
            <b-card-code title="Personal Details">
              <b-row>
                <b-col md="6">
                  <!-- firstname -->
                  <b-form-group label="First Name" label-for="firstname">
                    <validation-provider
                      #default="{ errors }"
                      name="firstname"
                      rules="required"
                    >
                      <b-form-input
                        id="firstname"
                        v-model="vendorData.firstname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="jhon"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- lastname -->
                <b-col md="6">
                  <b-form-group label="Last Name" label-for="lastname">
                    <validation-provider
                      #default="{ errors }"
                      name="lastname"
                      rules="required"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="vendorData.lastname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="wick"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- idNumber -->
                <b-col md="6">
                  <b-form-group label="ID Number" label-for="idnumber">
                    <validation-provider
                      #default="{ errors }"
                      name="ID Number"
                      rules="required"
                    >
                      <b-form-input
                        id="idnumber"
                        v-model="vendorData.idnumber"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter 14 number of vendor ID"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Email -->
                <b-col md="6">
                  <b-form-group label="Email" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="vendorData.email"
                        type="email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Example@MobileMasr.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- BirthDate -->
                <b-col md="6">
                  <b-form-group label="Birthdate" label-for="Birthdate">
                    <flat-pickr
                      v-model="vendorData.birthdate"
                      class="form-control"
                      placeholder="12/12/2020"
                      :config="{
                        maxDate: maxDate,
                      }"
                    />
                  </b-form-group>
                </b-col>
                <!-- password -->
                <b-col md="6">
                  <b-form-group label="Password" label-for="password">
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|password"
                    >
                      <b-form-input
                        id="password"
                        v-model="vendorData.password"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- confirm password -->
                <b-col md="6">
                  <b-form-group label="Confirm Password" label-for="c-password">
                    <validation-provider
                      #default="{ errors }"
                      name="Password Confirm"
                      rules="required|confirmed:Password"
                    >
                      <b-form-input
                        id="c-password"
                        v-model="vendorData.password_confirmation"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Re-type Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-code>
          </b-col>
          <!-- vendor Catigory Option -->
          <b-col cols="6" v-for="(category,index)  in  vendorData.category_option" :key="index">
            <b-card-code :title="category.text">
              <b-row>
                <!-- Portal Option -->
                <b-col md="3">
                  <b-form-group label="Can Sell Portal New Products ?" label-for="is_portal_new">
                    <validation-provider
                      #default="{ errors }"
                      name="is_portal_new"
                      rules=""
                    >
                      <b-form-checkbox switch v-model="category.is_portal_new">
                        <p v-if="category.is_portal_new">
                          Can Upload Portal New Products
                        </p>
                        <p v-else>Can't Upload Portal New Products</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- InStore Option -->
                <b-col md="3">
                  <b-form-group label="Can Sell Portal Used Products ?" label-for="is_portal_used">
                    <validation-provider
                      #default="{ errors }"
                      name="is_portal_used"
                      rules=""
                    >
                      <b-form-checkbox switch v-model="category.is_portal_used">
                        <p v-if="category.is_portal_used">
                          Can Upload Portal Used Products
                        </p>
                        <p v-else>Can't Upload Portal Used Products</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Sell New Option -->
                <b-col md="3"> 
                  <b-form-group label="Can Sell InStore New Products ?" label-for="is_instore_new">
                    <validation-provider
                      #default="{ errors }"
                      name="is_instore_new"
                      rules=""
                    >
                      <b-form-checkbox switch v-model="category.is_instore_new">
                        <p v-if="category.is_instore_new">
                          Can Sell InStore New Products
                        </p>
                        <p v-else>Can't Sell InStore New Products</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Sell Used Option -->
                <b-col md="3">
                  <b-form-group
                    label="Can Sell InStore Used Products ?"
                    label-for="is_sell_used"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="is_sell_used"
                      rules=""
                    >
                      <b-form-checkbox switch v-model="category.is_instore_used">
                        <p v-if="category.is_instore_used">
                          Can Sell InStore Used Products
                        </p>
                        <p v-else>Can't Sell InStore  Used Products</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-code>
          </b-col>
           <!-- Sell Used Option -->
           <b-card-code title="Products Price">
           <b-col md="12">
                   <b-form-group label="Can Update Products Price ?" label-for="can_update_price">
                        <validation-provider
                          #default="{ errors }"
                          name="can_update_price"
                          rules="required"
                        >
                          <b-form-checkbox switch v-model="vendorData.can_update_price">
                            <p v-if="vendorData.can_update_price">
                              Can Update Products Price 
                            </p>
                            <p v-else>Can't Update Products Price</p>
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                </b-col>
            </b-card-code>
          <!-- vendor Details -->
          <b-col cols="12">
            <b-card-code title="Dealer Details">
              <b-row>
                <!-- storeName -->
                <b-col md="6">
                  <b-form-group label="Store Name" label-for="storename">
                    <validation-provider
                      #default="{ errors }"
                      name="Store Name"
                      rules="required"
                    >
                      <b-form-input
                        id="storename"
                        v-model="vendorData.storename"
                        :state="errors.length > 0 ? false : null"
                        placeholder="MobileMasr"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- type -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <b-form-group
                      label="Type"
                      label-for="type"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="type"
                        v-model="typeSelect"
                        :state="typeSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="typeOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- address -->
                <b-col md="6">
                  <b-form-group label="Address" label-for="address">
                    <validation-provider
                      #default="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <b-form-input
                        id="address"
                        v-model="vendorData.address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="maddi"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- phone -->
                <b-col md="6">
                  <b-form-group label="Phone" label-for="phone">
                    <validation-provider
                      #default="{ errors }"
                      name="Phone"
                    >
                      <b-form-input
                        id="phone"
                        v-model="vendorData.phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="XXXXXXXXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- country -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <b-form-group
                      label="Country"
                      label-for="country"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="country"
                        v-model="countrySelect"
                        :state="countrySelect == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- city -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <b-form-group
                      label="City"
                      label-for="city"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="city"
                        v-model="citySelect"
                        :disabled="city"
                        :state="citySelect == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="cityOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- area -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Area"
                    rules="required"
                  >
                    <b-form-group
                      label="Area"
                      label-for="area"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="area"
                        v-model="areaSelect"
                        :disabled="area"
                        :state="areaSelect == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="areaOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- location -->
                <b-col md="6">
                  <b-form-group label="Location" label-for="location">
                    <validation-provider
                      #default="{ errors }"
                      name="Location"
                      rules="required"
                    >
                      <b-form-input
                        id="location"
                        v-model="vendorData.orderlocation"
                        :state="errors.length > 0 ? false : null"
                        placeholder="maddi"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- self management -->
                <b-col md="6" v-if="!is_account_manager">
                  <validation-provider
                    #default="{ errors }"
                    name="Self management"
                    rules="required"
                  >
                    <b-form-group
                      label="self management"
                      label-for="self management"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="self-management"
                        v-model="self_managementSelected"
                        :state="self_managementSelected == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="self_managementOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- account manager -->
                <b-col
                  md="6"
                  v-if="
                    self_managementSelected != null &&
                    self_managementSelected.value == '0'
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Account manager"
                    rules="required"
                  >
                    <b-form-group
                      label="account manager"
                      label-for="account manager"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="account-manager"
                        v-model="account_managerSelected"
                        :state="account_managerSelected == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="account_managerOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-code>
          </b-col>
          <!-- mobilesNumber -->
          <b-col cols="12">
            <b-card-code title="Mobile Numbers">
              <div>
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in vendorData.phoneNumbers"
                  :id="item.id"
                  :key="item.id"
                >
                  <!-- Mobile Number -->
                  <b-col md="6">
                    <b-form-group
                      label="Mobile Number"
                      label-for="mobilenumber0"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Mobile Number"
                        rules="required|egyptMobile"
                      >
                        <b-form-input
                          :id="'mobilenumber' + index"
                          v-model="item.mobile"
                          :state="errors.length > 0 ? false : null"
                          placeholder="0115XXXXXXX"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col lg="2" md="3" class="mb-50">
                    <b-button
                      v-if="index > 0"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <!-- add new button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-card-code>
          </b-col>
          <!-- internal manager -->
          <b-col cols="12">
            <b-card title="Internal Manager">
              <b-row>
                <b-col md="6">
                  <!-- name -->
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="internal_manage_name"
                        v-model="vendorData.internal_manager_name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="jhon"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Mobile Number" label-for="mobilenumber">
                    <validation-provider
                      #default="{ errors }"
                      name="Mobile Number"
                      rules="required|egyptMobile"
                    >
                      <b-form-input
                        v-model="vendorData.internal_manager_phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="0115XXXXXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- official Documents -->
          <b-col cols="12">
            <b-card-code title="Official Documents">
              <div>
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in vendorData.official_docs"
                  :id="index"
                  :key="index"
                >
                  <!-- file -->
                  <b-col md="2">
                    <b-form-group label="File" label-for="file">
                      <input
                        type="file"
                        multiple="multiple"
                        class="form-control-file"
                        name="file"
                        id="official_file"
                        ref="file"
                        change
                        @change="fileUploadIndex($event, item)"
                        accept="application/pdf"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- type -->

                  <b-col md="4">
                    <b-form-group label="type" label-for="Type">
                      <v-select
                        :disabled="typeSelect == null"
                        id="sType"
                        v-model="typeFileSelect[index]"
                        :state="typeFileSelect[index] == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="typeFileOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- fileNumber -->
                  <b-col md="4">
                    <b-form-group label="File Number" label-for="filenumber">
                      <validation-provider
                        #default="{ errors }"
                        name="File Number"
                        rules="integer"
                      >
                        <b-form-input
                          id="filenumber"
                          v-model="item.number"
                          placeholder="XXXXXXXXXX"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col md="2" class="mb-50">
                    <b-button
                      v-if="index > 0"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItemOfficial(index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <!-- add new button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgainOfficial"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-card-code>
          </b-col>
          <!-- Payouts Accounts -->
          <b-col cols="12">
            <b-card-code title="Payouts Accounts">
              <div>
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in vendorData.payouts_accounts"
                  :id="index"
                  :key="index"
                >
                  <b-col md="12">
                    <h4 class="mb-3">Account {{ index + 1 }}</h4>
                  </b-col>

                  <!-- Account Type -->
                  <b-col md="4">
                    <b-form-group label="Account Type" label-for="account_type">
                      <v-select
                        id="account_type"
                        v-model="item.account_type"
                        @input="updateProviderName(index)"
                        :state="item.account_type == null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="payoutAccountsType"
                        :selectable="
                          (option) => !option.includes('select_value')
                        "
                        label="text"
                      />
                    </b-form-group>
                  </b-col>

                  <!--Bank  name -->
                  <b-col md="4" v-if="item.account_type == 'Bank'">
                    <b-form-group label="Bank Name" label-for=" Bankname">
                      <validation-provider
                        #default="{ errors }"
                        name="Bank Name"
                        :rules="
                          item.account_type != null &&
                          item.account_type == 'Bank'
                            ? 'required'
                            : ''
                        "
                      >
                        <v-select
                          id="Bank-name"
                          v-model="item.provider_name"
                          :state="item.provider_name == null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="payoutBankTypes"
                          :selectable="
                            (option) => !option.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--Wallet  name -->
                  <b-col md="4" v-if="item.account_type == 'Wallet'">
                    <b-form-group label="Wallet Name" label-for="Walletname">
                      <validation-provider
                        #default="{ errors }"
                        name="Wallet Name"
                        :rules="
                          item.account_type != null &&
                          item.account_type == 'Wallet'
                            ? 'required'
                            : ''
                        "
                      >
                        <v-select
                          id="Wallet-name"
                          v-model="item.provider_name"
                          :state="item.provider_name == null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="payoutWalletTypes"
                          :selectable="
                            (option) => !option.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Account Number -->
                  <b-col md="4">
                    <b-form-group
                      label="Acoount Number"
                      label-for="Acoount Number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Acoount Number"
                        :rules="
                          item.account_type != null
                            ? 'integer|required'
                            : 'integer'
                        "
                      >
                        <b-form-input
                          id="Acoount-Number"
                          v-model="item.number"
                          :state="errors.length > 0 ? false : null"
                          placeholder="XXXXXXXXXX"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Account Name -->
                  <b-col md="4" v-if="item.account_type == 'Bank'">
                    <b-form-group label="Acoount Name" label-for="Acoount Name">
                      <validation-provider
                        #default="{ errors }"
                        name="Account Name"
                        :rules="
                          item.account_type != null &&
                          item.account_type == 'Bank'
                            ? 'required'
                            : ''
                        "
                      >
                        <b-form-input id="Acoount-Name" v-model="item.name" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Account IBAN -->
                  <b-col md="4" v-if="item.account_type == 'Bank'">
                    <b-form-group label="Acoount IBAN" label-for="Acoount IBAN">
                      <validation-provider
                        #default="{ errors }"
                        name="Acoount IBAN"
                        :rules="
                          item.account_type != null &&
                          item.account_type == 'Bank'
                            ? 'required'
                            : ''
                        "
                      >
                        <b-form-input id="Acoount-IBAN" v-model="item.iban" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- file -->
                  <b-col md="2">
                    <b-form-group label="File" label-for="file">
                      <input
                        type="file"
                        multiple="multiple"
                        class="form-control-file"
                        name="file"
                        id="file"
                        ref="file"
                        change
                        @change="fileUploadIndex($event, item)"
                        accept="application/pdf"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col md="2" class="mb-50">
                    <b-button
                      v-if="index > 0"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItemPayouts(index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                    </b-button>
                  </b-col>
                  <b-col md="12" v-if="vendorData.payouts_accounts.length > 1">
                    <hr />
                  </b-col>
                </b-row>
              </div>
              <!-- add new button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgainPayouts"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-card-code>
          </b-col>
          <!-- images -->
          <b-col cols="12">
            <b-card-code title="Images">
              <b-row>
                <!-- Profile picture -->
                <b-col md="6">
                  <b-form-group
                    label="Profile picture"
                    label-for="profile-picture"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Profile picture"
                      rules="required"
                    >
                      <b-form-file
                        v-model="ProfilePictureBase64"
                        @change="uploadImage"
                        id="ProfilePictureBase64"
                        accept="image/*"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Id front picture -->
                <b-col md="6">
                  <b-form-group
                    label="Id front picture"
                    label-for="id-front-picture"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Id front picture"
                      rules="required"
                    >
                      <b-form-file
                        v-model="ProfilePictureBase642"
                        @change="uploadImage2"
                        id="ProfilePictureBase642"
                        accept="image/*"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Id back picture -->
                <b-col md="6">
                  <b-form-group
                    label="Id back picture"
                    label-for="id-back-picture"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Id back picture"
                      rules="required"
                    >
                      <b-form-file
                        v-model="ProfilePictureBase643"
                        @change="uploadImage3"
                        id="ProfilePictureBase643"
                        accept="image/*"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-code>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-card>
              <b-button variant="primary" block @click="validationForm">
                Submit
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  required,
  email,
  integer,
  egyptID,
  egyptMobile,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { heightTransition } from '@core/mixins/ui/transition'
// import axios from '@/libs/axios'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    flatPickr,
  },

  mixins: [heightTransition],
  data() {
    return {
      maxDate: null,
      area: true,
      city: true,
      success_message: '',
      showDismissibleAlert: false,
      errors_back: [],
      is_account_manager: false,
      required,
      email,
      integer,
      egyptID,
      egyptMobile,
      ProfilePictureBase64: [],
      ProfilePictureBase642: [],
      ProfilePictureBase643: [],
      filed: [],
      files: null,
      countryOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      self_managementOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '1',
          text: 'True',
        },
        {
          value: '0',
          text: 'False',
        },
      ],
      typeFileOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      typeFileSelect: [null],
      self_managementSelected: null,
      account_managerSelected: null,
      account_managerOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      countrySelect: null,
      cityOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      citySelect: null,
      areaOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      areaSelect: null,
      typeSelect: null,
      typeOptions: [
        {
          text: 'Agency',
          value: 'agency',
        },
        {
          text: 'Company',
          value: 'company',
        },
        {
          text: 'Personal',
          value: 'personal',
        },
      ],
      vendorData: {
        firstname: '',
        lastname: '',
        idnumber: '',
        email: '',
        storename: '',
        birthdate: '',
        address: '',
        phone: '',
        area_id: '',
        orderlocation: '',
        password: '',
        // portal: false,
        // in_store: false,
        // is_sell_new: false,
        // is_sell_used: false,
        can_update_price: false,
        account_manager_id: null,
        self_management: null,
        password_confirmation: '',
        internal_manager_name: null,
        internal_manager_phone: null,
        phoneNumbers: [
          {
            mobile: null,
          },
        ],
        official_docs: [
          {
            number: null,
            file: null,
            number: null,
          },
        ],
        payouts_accounts: [
          {
            type: null,
            name: null,
            iban: null,
            file: null,
            number: null,
            provider_name: null,
          },
        ],
        profile_picture: '',
        idfront_picture: '',
        idback_picture: '',
        category_option: []
      },
      payoutAccountsType: [],
      payoutBankTypes: [],
      payoutWalletTypes: [],
      providerNames: [],
    }
  },
  watch: {
    countrySelect: function () {
      this.countrySelected()
    },
    countrySelect: function () {
      this.countrySelected()
    },
    citySelect: function () {
      this.citySelected()
    },
    self_managementSelected: function () {
      if (this.self_managementSelected.value == '0') {
        this.getAdmins()
      }
    },
    typeSelect: function () {
      if (this.typeSelect.value == 'personal') {
        this.typeFileOptions = [
          {
            value: 'select_value',
            text: 'Select Value',
          },
          {
            value: 'contract',
            text: 'Contract',
          },
        ]
      } else {
        this.typeFileOptions = [
          {
            value: 'select_value',
            text: 'Select Value',
          },
          {
            value: 'commercial_register ',
            text: 'Commercial Register ',
          },
          {
            value: 'tax_card ',
            text: 'Tax Card ',
          },
          {
            value: 'contract',
            text: 'Contract',
          },
        ]
      }
    },
  },

  async created() {
    this.maxDate = moment().subtract(18, 'years').format('YYYY-MM-DD')
    
    this.getCategories();

    axios
      .get('get/role')
      .then((result) => {
        // //console.log(result.data.data)
        const data = result.data.data
        if (data.name == 'account_manager') {
          this.is_account_manager = true
        } else if (data.name == 'sellers_moderator') {
          this.self_managementOptions.splice(1, 1)
        }
      })
      .catch((err) => {
        this.is_account_manager = false
      })
    // const dFormat = moment(d, "MM DD YYYY");
    // //console.log(this.maxDate)
    await axios
      .get('vendors/create')
      .then((result) => {
        this.setCountriesData(result.data.data.countries)
        this.setPayoutAccountsData(result.data.data.payoutAccountsData)
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })

    // //console.log(this.is_account_manager)
  },
  methods: {
    updateProviderName(index) {
      this.vendorData.payouts_accounts[index].provider_name = null
      this.vendorData.payouts_accounts[index].name = null
      this.vendorData.payouts_accounts[index].iban = null
      this.vendorData.payouts_accounts[index].file = null
      this.vendorData.payouts_accounts[index].number = null
    },
    // set countries data to countryOption
    setCountriesData(countriesData) {
      for (let ind in countriesData) {
        this.countryOptions.push({
          text: countriesData[ind].name,
          value: countriesData[ind].id.toString(),
        })
      }
    },

    // set countries data to countryOption
    setPayoutAccountsData(payoutAccountsData) {
      this.payoutAccountsType = payoutAccountsData.payoutAccountsType
      this.payoutBankTypes = payoutAccountsData.payoutBankTypes
      this.payoutWalletTypes = payoutAccountsData.payoutWalletTypes
    },
    // sumbit new vendor
    validationForm() {


      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.vendorData.type = this.typeSelect.value
          this.vendorData.area_id = this.areaSelect.value
          if (this.vendorData.profile_picture.includes(',')) {
            this.vendorData.profile_picture = this.vendorData.profile_picture
              .split(',')
              .map((item) => item.trim())
            this.vendorData.profile_picture = this.vendorData.profile_picture[1]
          }
          if (this.vendorData.idfront_picture.includes(',')) {
            this.vendorData.idfront_picture = this.vendorData.idfront_picture
              .split(',')
              .map((item) => item.trim())
            this.vendorData.idfront_picture = this.vendorData.idfront_picture[1]
          }
          if (this.vendorData.idback_picture.includes(',')) {
            this.vendorData.idback_picture = this.vendorData.idback_picture
              .split(',')
              .map((item) => item.trim())
            this.vendorData.idback_picture = this.vendorData.idback_picture[1]
          }

          if (!this.is_account_manager) {
            this.vendorData.self_management = this.self_managementSelected.value
          } else {
            this.vendorData.self_management = '0'
          }
          // check and validate phone data
          if (this.vendorData.phone != '') {
            this.vendorData.phone = parseInt(this.vendorData.phone)
          }
          let formData = new FormData()
          // formData.append('file', this.files);
          formData.append('self_management', this.vendorData.self_management)
          if (this.vendorData.self_management == '0') {
            if (this.account_managerSelected != null) {
              this.vendorData.account_manager_id =
                this.account_managerSelected.value
              formData.append(
                'account_manager_id',
                this.vendorData.account_manager_id
              )
            }
          }

          formData.append(
            'internal_manager_name',
            this.vendorData.internal_manager_name
          )
          formData.append(
            'internal_manager_phone',
            this.vendorData.internal_manager_phone
          )

          formData.append('firstname', this.vendorData.firstname)
          formData.append('lastname', this.vendorData.lastname)
          formData.append('idnumber', this.vendorData.idnumber)
          formData.append('email', this.vendorData.email)
          formData.append('storename', this.vendorData.storename)
          formData.append('birthdate', this.vendorData.birthdate)
          formData.append('address', this.vendorData.address)
          if (this.vendorData.phone != '') {
            formData.append('phone', this.vendorData.phone)
          }
          formData.append('area_id', this.vendorData.area_id)
          formData.append('orderlocation', this.vendorData.orderlocation)
          formData.append('password', this.vendorData.password)
          formData.append(
            'password_confirmation',
            this.vendorData.password_confirmation
          )
          formData.append('profile_picture', this.vendorData.profile_picture)
          formData.append('idfront_picture', this.vendorData.idfront_picture)
          formData.append('idback_picture', this.vendorData.idback_picture)
          formData.append('type', this.typeSelect.value)
          formData.append('phoneNumbers', this.vendorData.phoneNumbers)
          // formData.append('portal', this.vendorData.portal == true ? 1 : 0)
          // formData.append('in_store', this.vendorData.in_store == true ? 1 : 0)
          formData.append('can_update_price', this.vendorData.in_store == true ? 1 : 0)
          // formData.append('is_sell_new', this.vendorData.is_sell_new == true ? 1 : 0)
          // formData.append('is_sell_used', this.vendorData.is_sell_used == true ? 1 : 0)




      // set official_docs in category_option
      if (this.vendorData.category_option) {
        this.vendorData.category_option.reduce(
              (formData, category, index) => {
                if(category['is_portal_new'] != undefined || category['is_portal_used'] != undefined ||category['is_instore_new'] != undefined ||category['is_instore_used'] != undefined ){
                  if(formData != undefined){
                    formData.append(
                      'category_option[' + index + '][id]',
                      category['value']
                    )
                    formData.append(
                      'category_option[' + index + '][is_portal_new]',
                      category['is_portal_new']
                    )
                    formData.append(
                      'category_option[' + index + '][is_portal_used]',
                      category['is_portal_used']
                    )
                    formData.append(
                      'category_option[' + index + '][is_instore_new]',
                      category['is_instore_new']
                    )
                    formData.append(
                      'category_option[' + index + '][is_instore_used]',
                      category['is_instore_used']
                    )
                
                return formData
              }
              }
              },
              formData
            );
          }

          // set phones Number in formData
          this.vendorData.phoneNumbers.reduce((formData, phoneData, index) => {
            formData.append(
              'phoneNumbers[' + index + '][mobile]',
              phoneData.mobile
            )

            return formData
          }, formData)

          // set official_docs in formData
          this.vendorData.official_docs.reduce(
            (formData, officialData, index) => {
              officialData.name = this.typeFileSelect[index].value
              formData.append(
                'official_docs[' + index + '][file]',
                officialData.file
              )
              formData.append(
                'official_docs[' + index + '][number]',
                officialData.number
              )
              formData.append(
                'official_docs[' + index + '][name]',
                officialData.name
              )
              return formData
            },
            formData
          )

          // set official_docs in formData
          this.vendorData.payouts_accounts.reduce(
            (formData, payoutsAccount, index) => {
              formData.append(
                'payouts_accounts[' + index + '][account_type]',
                payoutsAccount.account_type
              )
              formData.append(
                'payouts_accounts[' + index + '][file]',
                payoutsAccount.file
              )
              formData.append(
                'payouts_accounts[' + index + '][number]',
                payoutsAccount.number
              )
              formData.append(
                'payouts_accounts[' + index + '][provider_name]',
                payoutsAccount.provider_name
              )

              if (payoutsAccount.account_type == 'Bank') {
                formData.append(
                  'payouts_accounts[' + index + '][name]',
                  payoutsAccount.name
                )
                formData.append(
                  'payouts_accounts[' + index + '][iban]',
                  payoutsAccount.iban
                )
              }

              return formData
            },
            formData
          )

          axios
            .post('vendors', formData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push('/Vendor/Index')
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // get admins
    getAdmins() {
      axios
        .get('admins/account-manager')
        .then((result) => {
          //console.log(result)
          this.account_managerOptions = [
            {
              value: 'select_value',
              text: 'Select Value',
            },
          ]
          const data = result.data.data
          for (let index in data) {
            this.account_managerOptions.push({
              text: data[index].firstname + ' ' + data[index].lastname,
              value: data[index].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },


    getCategories(){
      axios
      .get('categories')
      .then((result) => {
        const data = result.data.data
        for (let cat in data) {
          if (data[cat].translation != null) {
            this.vendorData.category_option.push({
              value: data[cat].id.toString(),
              text: data[cat].translation.name
            })
          } else {
            this.vendorData.category_option.push({
              value: data[cat].id.toString(),
              text: 'undefinded',
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    },
    // upload file
    fileUpload(e) {
      this.files = e.target.files[0]
    },
    fileUploadIndex(event, item) {
      // console.log(item)
      item.file = event.target.files[0]
      // console.log(item.file)
    },
    // cencode profile picture
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.vendorData.profile_picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // encode id front pictuer
    uploadImage2(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage2(files[0])
    },
    createImage2(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.vendorData.idfront_picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // encode id back picture
    uploadImage3(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage3(files[0])
    },
    createImage3(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.vendorData.idback_picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // REPETED FORM
    repeateAgain() {
      this.vendorData.phoneNumbers.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem(index) {
      if (this.vendorData.phoneNumbers.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.vendorData.phoneNumbers.splice(index, 1)
      }
    },
    // REPETED FORM
    repeateAgainPayouts() {
      this.vendorData.payouts_accounts.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.typeFileSelect.push()
    },
    removeItemPayouts(index) {
      if (this.vendorData.payouts_accounts.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.vendorData.payouts_accounts.splice(index, 1)
        this.typeFileSelect.splice(index, 1)
      }
    },
    // REPETED FORM
    repeateAgainOfficial() {
      this.vendorData.official_docs.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItemOfficial(index) {
      if (this.vendorData.official_docs.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.vendorData.official_docs.splice(index, 1)
      }
    },
    // when country select
    countrySelected() {
      this.city = false
      axios
        .get('country/' + this.countrySelect.value + '/cities')
        .then((result) => {
          const data = result.data.data
          this.cityOptions = [
            {
              value: 'select_value',
              text: 'Select Value',
            },
          ]
          for (let ind in data) {
            this.cityOptions.push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // when city select
    citySelected() {
      this.area = false
      axios
        .get('cities/' + this.citySelect.value + '/areas')
        .then((result) => {
          const data = result.data.data
          this.areaOptions = [
            {
              value: 'select_value',
              text: 'Select Value',
            },
          ]
          for (let ind in data) {
            this.areaOptions.push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
</style>
